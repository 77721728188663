@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


* {
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

.clearfix {
    clear: both;
    display: block;
    content: "";
}

img {
    display: block;
}

html,
body {
    height: 100%;
}

body {
    padding: 0 0 80px 0;
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($fg: 1, $fs: 0, $fb: auto) {
    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so lets grab the
    // first item in the list and just return that.
    @if type-of($fg)=='list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box-flex: $fg-boxflex;
    -moz-box: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit/FF Box fallback.
    -webkit-flex-wrap: $value;

    @if $value ==nowrap {
        -ms-flex-wrap: none;
    }

    @else {
        -ms-flex-wrap: $value;
    }

    flex-wrap: $value;
}

@mixin align-items($value: stretch) {
    @if $value ==flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    }

    @else if $value ==flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    }

    @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }

    -webkit-align-items: $value;
    align-items: $value;
}

@mixin justify-content($value: flex-start) {
    @if $value ==flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    }

    @else if $value ==flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    }

    @else if $value ==space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    }

    @else if $value ==space-around {
        -ms-flex-pack: distribute;
    }

    @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin transition($property: all, $funtion: ease, $time: 0.35s) {
    transition: $property $funtion $time;
    -webkit-transition: $property $funtion $time;
    -moz-transition: $property $funtion $time;
    -ms-transition: $property $funtion $time;
    -o-transition: $property $funtion $time;
}

@mixin rotate($deg: 0deg) {
    transform: rotate($deg);
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
}

.alignMiddle{
    @include align-items(center);
}

$base-color: #1D2449;
$orange-color: #D69951;
$orange-color-dark: #E8A659;
$white: #ffffff;
$dark: #484848;

.row {
    @include flexbox();
    @include flex-wrap(wrap);

    &.align-center {
        @include align-items(center);
    }

    &.justify-center {
        @include justify-content(center);
    }

    &.align-bottom {
        @include align-items(flex-end);
    }

    &.align-top {
        @include align-items(flex-start);
    }

    &.align-self-start {
        align-self: flex-start;
    }
}


.container {
    width: 1000px;
    margin: 0 auto;
}
.container-fluid{
    width: 1280px;
    margin: 0 auto;
}

.col4 {
    @include flex(0, 0, 33.3333333333%);
    max-width: 33.3333333333%;
}

.col6 {
    @include flex(0, 0, 50%);
    max-width: 50%;
}

.col8 {
    @include flex(0, 0, 66.6666666667%);
    max-width: 66.6666666667%;
}

.header {
    background: $base-color;
    padding: 20px 0;

    .rightLogo {
        @include flexbox;
        @include justify-content(flex-end);
    }
}

.logout {
    text-align: right;
    color: $orange-color;
    font-size: 14px;
    font-weight: 500;

    button {
        color: $orange-color;
        text-decoration: none;
        @include transition(all, 0.5s, ease);
        background: none;
        border: 0px;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            color: $white;
        }
    }
}

.footer {
    text-align: center;
    background: $base-color;
    padding: 20px 0;
    color: $white;
    font-size: 14px;
}

.projectDetailWrap{
    min-height: calc(100vh - 143px);
}

.form-wrap {
    width: 380px;
    margin: 0 auto;
    min-height: calc(100vh - 143px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
        width: 100%;
        border: 0px;
        border-bottom: 1px solid #707070;
        text-align: center;
        font-size: 18px;
        color: $dark;
        padding: 10px 0 10px 0;
        margin: 0 0 5px 0;
        outline: 0px !important;

        &::placeholder {
            color: $dark;
        }
    }

    .error {
        color: #f44336;
        font-size: 1.2rem;
        text-align: center;
        display: block;
    }

    .inputHolder {
        margin: 0 0 15px 0;
        text-align: center;
        width: 100%;

        button {
            display: inline-block;
            background: $orange-color;
            border-radius: 20px;
            font-size: 14px;
            color: $white;
            text-decoration: none;
            padding: 10px 50px;
            margin: 0 0 0 0;
            @include transition(all, 0.5s, ease);
            outline: 0px !important;
            cursor: pointer;
            border: 0px;
            text-transform: uppercase;

            &:hover {
                background: $base-color;
            }
        }
    }
}


/* Create new Project */
.new-project {
    background: #EAEAEA;
    padding: 45px 0;
    font-size: 22px;
    color: $dark;
    font-weight: 500;

    * {
        font-weight: 500;
    }

    a {
        display: inline-block;
        background: $orange-color;
        border-radius: 20px;
        font-size: 14px;
        color: $white;
        text-decoration: none;
        padding: 10px 20px;
        margin: 0 0 0 15px;
        @include transition(all, 0.5s, ease);

        &:hover {
            background: $base-color;
        }
    }
}

.recent-project {
    padding: 40px 0;

    .head {
        padding: 0 0 15px 0;

        h2 {
            font-size: 22px;
            color: $dark;
            font-weight: 500;
        }

        .search {
            @include flexbox;
            @include justify-content(flex-end);
            position: relative;

            .search-input {
                position: absolute;
                top: -5px;
                right: 35px;
                width: 240px;
                border: 0px;
                border: 1px solid #dcdcdc;
                text-align: left;
                font-size: 14px;
                color: $dark;
                padding: 10px 15px 10px 15px;
                margin: 0;
                outline: 0px !important;
                border-radius: 20px;
            }
        }

        svg {
            max-width: 25px;
            fill: $base-color;
        }
    }

    .table-wrap {
        height: 440px;
        overflow-y: scroll;
        position: relative;
        .table-row-wrap {
            @include flexbox;
            border-bottom: 1px solid #9F9F9F;

            div {
                font-size: 14px;
                font-weight: 500;
                color: $base-color;
                padding: 15px 10px;
            }

            .table-col1 {
                @include flex(0 0 10%);
            }

            .table-col2 {
                @include flex(0 0 30%);
            }

            .table-col3 {
                @include flex(0 0 15%);
            }

            .table-col4 {
                @include flex(0 0 15%);
            }

            .table-col5 {
                @include flex(0 0 15%);
            }

            .table-col6 {
                @include flex(0 0 15%);
            }

            &.table-row-head {
                background: #EAEAEA;
                text-align: left;
                border-top: 3px solid $base-color;
                left: 0;
                position: sticky;
                top: 0;
                width: 100%;
                z-index: 2;
                div {
                    font-size: 14px;
                    font-weight: 500;
                    color: $base-color;

                    &.table-col6 {
                        color: #D69951
                    }
                }
            }

            .action {
                @include flexbox;
                @include align-items(center);

                div {
                    padding: 0px;

                    &+div {
                        margin: 0 0 0 20px;
                    }
                }

                button {
                    background: none;
                    border: 0px;
                    cursor: pointer;
                    margin: 0 0 0 20px;
                }
            }

            svg {
                fill: $orange-color;
                @include transition(all, 0.5s, ease);

                &:hover {
                    fill: $base-color;
                }
            }

            .center {
                text-align: center;
            }
        }
    }
}

/* Create new Project Forms */
.create-project-wrap {
    padding: 40px 0;

    h2 {
        font-size: 22px;
        color: $dark;
        font-weight: 500;
        margin: 0 0 40px 0;
    }

    .title {
        font-size: 18px;
        color: $dark;
    }

    .row {
        margin-bottom: 30px;
        @include align-items(center);
    }

    .text {
        font-size: 18px;
        color: $dark;
        width: 100%;
        border-radius: 0px;
        border: 0px;
        border-bottom: 1px solid #707070;
        outline: 0px !important;
        padding: 5px 0;
    }

    .upload-wrap {
        border: 1px dotted $dark;
        padding: 0px;
        background: #EAEAEA;
        text-align: center;
        position: relative;
        margin: 0 0 20px 0;
        min-height: 194px;

        &:hover {
            .box_label {
                cursor: pointer;
            }

            .box__button {
                background: $orange-color-dark;
                color: $white;
            }
        }

        .uploadname {
            font-size: 14px;
            color: #484848;
            font-weight: 500;
            position: relative;
            top: 20px;
        }
    }

    .box__file {
        opacity: 0;
        inset: 0px;
        position: absolute;
        cursor: pointer;

        &::file-selector-button {
            cursor: pointer;
        }
    }

    .box__button {
        border: 1px solid $orange-color-dark;
        border-radius: 20px;
        background: $white;
        font-size: 14px;
        color: $orange-color-dark;
        padding: 9px 20px;
        font-weight: 500;
        cursor: pointer;
        @include transition(all, 0.5s, ease);
        position: absolute;
        bottom: 35px;
        left: 0px;
        right: 0px;
        max-width: 180px;
        margin: auto;
        pointer-events: none;
    }

    .box_label {
        font-size: 14px;
        color: $dark;
        font-weight: 500;
        line-height: 32px;
        display: block;
        padding: 50px 20px 80px 20px;
    }

    .create-project-btn {
        text-align: center;

        button {
            display: inline-block;
            background: $orange-color;
            border-radius: 20px;
            font-size: 14px;
            color: $white;
            text-decoration: none;
            padding: 10px 20px;
            margin: 0 0 0 15px;
            @include transition(all, 0.5s, ease);
            cursor: pointer;
            border: 0px;
            text-transform: uppercase;

            &:hover {
                background: $base-color;
            }
        }
    }

    .error {
        color: #f44336;
        font-size: 1.2rem;
        text-align: left;
        display: block;
        margin: 10px 0 0 0;
    }
}

/* Project Details */
.project-head {
    background: #EAEAEA;
    border-bottom: 3px solid #E0DACC;
    ul {
        @include flexbox;

        li {
            font-size: 14px;
            color: $dark;
            font-weight: 500;
            list-style: none;
            padding: 20px 40px;
            cursor: pointer;

            &.active {
                background: #E0DACC;
                font-weight: bold;
            }
        }
    }

    .col6 {
        align-self: center
    }

    .download {
        @include align-items(center);
        background-color: #ececec;
        cursor: pointer;
        @include flexbox;
        flex-direction: column;
        @include justify-content(center);
        margin-left: auto;
        min-height: 80px;
        padding: 10px 15px;
        position: fixed;
        right: 0;
        top: 140px;
        z-index: 999;
        svg {
            width: 22px;
            @include transition(all, 0.5s, ease);
        }

        .txt {
            color: #1D2449;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            margin-top: 10px;
            order: 2;
            text-align: right;
            @include transition(all, 0.5s, ease);
            span {
                display: block;
                font-size: 10px;
                text-transform: uppercase;
            }
        }

        &:hover {
            svg {
                fill: #D69951;
            }

            .txt {
                color: #D69951;
            }
        }
    }
}

.project-detail-table {
    padding: 0 0 60px;

    .table-wrap {
        position: relative;
        .table-row-wrap {
            display: flex;

            .table-col1 {
                @include flex(0 0 18%);
            }
            .table-col11 {
                @include flex(0 0 13%);
            }
            .table-col12 {
                @include flex(0 0 13%);
            }

            .table-col2 {
                @include flex(0 0 7.9%);
                text-align: center !important;
                &.cursorDisabled{
                    cursor: not-allowed
                }
            }

            .table-col3 {
                @include flex(0 0 7.9%);
                text-align: center !important;
                &.cursorDisabled{
                    cursor: not-allowed
                }
            }

            .table-col4 {
                @include flex(0 0 7.9%);
                text-align: center !important;
                &.cursorDisabled{
                    cursor: not-allowed
                }
            }

            .table-col5 {
                @include flex(0 0 7.9%);
                text-align: center !important;
                &.cursorDisabled{
                    cursor: not-allowed
                }
            }

            .table-col6 {
                @include flex(0 0 7.9%);
                text-align: center !important;
            }

            .table-col7 {
                @include flex(0 0 7.9%);
                text-align: center !important;
            }

            .table-col8 {
                @include flex(0 0 8.5%);
                text-align: center !important;
            }

            >div {
                border-bottom: 1px solid rgba(72, 72, 72, .3);
                border-right: 1px solid rgba(72, 72, 72, .3);
                font-size: 12px;
                font-weight: 500;
                padding: 18px 5px;
                position: relative;

                &:nth-child(1) {
                    background: #EAEAEA;
                    padding: 18px 20px;
                }
                &:nth-child(2),&:nth-child(3){
                    padding: 18px 15px;
                }

                &:last-child {
                    border-right: 0px;
                }

                &:nth-child(4) input[type="radio"]:checked+label {
                    background: #EEF2E7;
                }

                &:nth-child(5) input[type="radio"]:checked+label {
                    background: #E5FFF8
                }

                &:nth-child(6) input[type="radio"]:checked+label {
                    background: #FFECE5
                }

                &:nth-child(7) input[type="radio"]:checked+label {
                    background: #FFE5E5
                }
                &:nth-child(4) input[type="radio"]:disabled,&:nth-child(5) input[type="radio"]:disabled,&:nth-child(6) input[type="radio"]:disabled,&:nth-child(7) input[type="radio"]:disabled{
                    cursor: not-allowed;
                }
                &:nth-child(4) input[type="radio"]:disabled+label,&:nth-child(5) input[type="radio"]:disabled+label,&:nth-child(6) input[type="radio"]:disabled+label,&:nth-child(7) input[type="radio"]:disabled+label {
                    background-color: #f1f1f1;
                    cursor: not-allowed;
                }

                label {
                    pointer-events: none;
                    position: absolute;
                    inset: 0px;
                }

                input[type="radio"]+label svg {
                    max-width: 15px;
                    height: 12px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    opacity: 0;
                }

                input[type="radio"]:checked+label svg {
                    opacity: 1;
                }

                input[type="radio"] {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    inset: 0px;
                    cursor: pointer;
                }

            }

            &.table-row-head {
                left: 0;
                position: sticky;
                top: 0;
                width: 100%;
                z-index: 2;
                .countWrap{
                    display: block;
                    font-size: 16px;
                    margin-top: 5px;
                    text-align: center;
                    // &.one-one{
                    //     color: #99B967;
                    // }
                    // &.one-few{
                    //     color: #30ADC4;
                    // }
                    // &.one-many{
                    //     color: #E8A659;
                    // }
                    // &.demand-gen{
                    //     color: #EF8888;
                    // }
                }
                &::before{
                    background-color: #E0DACC;
                    content: '';
                    height: 100%;
                    left: -50vw;
                    position: absolute;
                    top: 0;
                    width: 150vw;
                }
                >div {
                    background: #E0DACC;
                    text-align: left;
                    color: $base-color;
                    font-size: 12px;
                    font-weight: 500;

                    &:nth-child(4) {
                        background: #E2EFCB;
                    }

                    &:nth-child(5) {
                        background: #C0E6DC;
                    }

                    &:nth-child(6) {
                        background: #F5D7B8;
                    }

                    &:nth-child(7) {
                        background: #FFC3C3;
                    }
                    &.disabled{
                        background-color: #d7d7d7;
                        color: #9f9f9f;
                    }
                    &.table-col1{
                        display: flex;
                        svg{
                            height: 15px;
                            margin-left: auto;
                            width: 15px;
                            &.asc{
                                path {
                                    &:first-child{
                                        fill: #979797
                                    }
                                }
                            }
                            &.desc{
                                path {
                                    &:last-child{
                                        fill: #979797
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

.chartWrap{
    background-color: #f1f1f1;
    padding-bottom: 30px;
}
.filterWrap{
    align-items: center;
    background-color: #1D2449;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
    .table-wrap .table-row-wrap.filterWrap{ 
        [class^="table-col"]{
            border: none;
            color: #ffffff;
            text-align: center;
            &.colSpan2{
                flex: 0 0 15.8%;
            }
            &.filterBlock{
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .table-col11,.table-col12{
            &.filterBlock{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &> div:nth-child(1),&> div:nth-child(2),&> div:nth-child(3){
            background-color: transparent;
        }
    }
    .filterInput{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #888FB0;
        color: #ffffff;
        font-weight: 600;
        height: 30px;
        outline: none!important;
        width: 100%;
    }
}

.loading {
    animation: loader 2s linear 0s infinite;
    height: 30px;
    stroke-dasharray: 160px;
    width: 30px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    position: absolute;
}

@keyframes loader {
    0% {
        stroke-dashoffset: 70px;
        @include rotate(0deg);
    }

    100% {
        stroke-dashoffset: 70px;
        @include rotate(360deg);
    }
}